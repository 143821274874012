<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.my_statement')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">
          {{ $t("withdraw.task_money") }}({{ $t("reservation.unit") }})
        </p>
        <p class="value">{{ this.win_money.toFixed(2) }}</p>
        <p class="tip">{{ $t("reservation.win_formula") }}</p>
      </div>
      <van-cell :title="$t('reservation.chooseDate')" :value="date" @click="show = true" />
      <van-calendar
        :title="$t('reservation.date')"
        :confirm-text="$t('reservation.date')"
        v-model="show"
        type="range"
        @confirm="onConfirm"
        :min-date="minDate"
        :max-date="maxDate"
        :show-title="false"
        :show-subtitle="false"
        :show-mark="false"
      />
      <div class="content">
        <div class="datalist">
          <div class="datalistitem">
            <div class="datalistitemValue">
              {{ this.personalreport.play_money }}
            </div>
            <div class="datalistitemKey">{{ $t("withdraw.task_money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">
              {{ this.personalreport.recharge }}
            </div>
            <div class="datalistitemKey">
              {{ $t("withdraw.recharge_money") }}
            </div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">
              {{ this.personalreport.withdrawal }}
            </div>
            <div class="datalistitemKey">{{ $t("withdraw.money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">
              {{ this.personalreport.win_money }}
            </div>
            <div class="datalistitemKey">{{ $t("withdraw.win_money") }}</div>
            <div class="datalistitemRightLine"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Calendar } from "vant";
export default {
  data() {
    return {
      win_money: 0,
      personalreport: {},
      show: false,
      date: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },

    getPersonalreport() {
      this.$http({
        method: "get",
        url: "user_get_personalreport",
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    setPersonalreport(send) {
      this.$http({
        method: "post",
        url: "user_set_personalreport",
        data: send || {},
      }).then((res) => {
        if (res.code === 200) {
          this.personalreport = res.data;
          this.win_money =
            this.personalreport.win_money - this.personalreport.play_money;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.setPersonalreport({
        startDate:start,
        endDate:end,
      });
    },
  },
  created() {
    Vue.use(Calendar);
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getPersonalreport();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  background-color: transparent;
  color: #fff;
}
.nav-bar {
  background: transparent;
}
.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title {
  font-size: 35px;
  color: #fff;
}
.van-cell {
  padding: 20px;
}
.van-cell span {
  font-size: 30px !important;
}
.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  // border-bottom: 1px solid #fff;
}
.container .header .info .tip {
  font-size: 30px;
  color: #fff;
}
.container .content {
  flex: 1;
}
.container .content .datalist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.container .content .datalist .datalistitem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue {
  color: #F78CA0;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey {
  color: #979799;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.van-popup {
  background: linear-gradient(180deg, #0E7041, #04391D);
}
/deep/ .van-calendar {
  background: linear-gradient(180deg, #0E7041, #04391D);
  color: #fff;
 
}
/deep/ .van-calendar__day {
  font-size: 24px;
}
/deep/ .van-button--normal {
  font-size: 24px;
}
</style>
